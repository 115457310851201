import React, { useRef } from "react";
import { Sphere } from "@react-three/drei";
import { TextureLoader } from "three";
import { useLoader } from "@react-three/fiber";
import { useFrame } from "@react-three/fiber";
// const VideoSphere = ({ videoURL }) => {
//   const sphereRef = useRef();

//   useFrame(() => {
//     if (sphereRef.current) {
//       sphereRef.current.rotation.x += 0.002;
//       sphereRef.current.rotation.y += 0.002;
//     }
//   });

//   return (
//     <Sphere ref={sphereRef} args={[5, 64, 64]}>
//       <meshBasicMaterial attach="material">
//         <videoTexture attach="map" args={[videoURL]} />
//       </meshBasicMaterial>
//     </Sphere>
//   );
// };
// export default VideoSphere;

const ImageSphere = ({ imageUrl }) => {
    let sphereRef = useRef();
  const texture = useLoader(TextureLoader, imageUrl);


  return (
    <>
      <Sphere ref={sphereRef} args={[50, 640, 640]} rotation={[0, 160, 0.4]}>
        <pointLight position={[10, 10, 10]} intensity={1} />

        <ambientLight intensity={1} />
        <meshBasicMaterial map={texture} side={2}></meshBasicMaterial>
      </Sphere>
    </>
  );
};

export default ImageSphere;
