import * as THREE from 'three'
import { useEffect } from 'react'
import { useThree } from '@react-three/fiber'
import { CameraControls, OrbitControls } from '@react-three/drei'
import { useRoute } from 'wouter'
import { useCamera } from '../camContext/cameraContext'
import { Vector3 } from 'three'
import { useFrame } from '@react-three/fiber'

function Rig() {
  let isMobile   = window.innerWidth < 768 ? true : false
  const locationx_change = 100;

  const { scene, controls,camera, mouse } = useThree()
  const [, params] = useRoute('/item/:id')
  const { cameraPosition,setmainChangingView,mainChangingView,moveCamera,moveCameraDown,setmoveCameraDown, cameraFocus, changingView, setchangingView, view ,mainView,setView,setmainView,setShowUI} = useCamera()
  const cameraMover=()=>{

    if(isMobile){
      cameraPosition[1] = cameraPosition[1]-4
      cameraFocus[1] = cameraFocus[1]-4
      cameraPosition[2] = cameraPosition[2]+6
    }else{
      cameraPosition[1] = cameraPosition[1]-6
      cameraFocus[1] = cameraFocus[1]-6
      cameraPosition[2] = cameraPosition[2]+5
    }

    controls?.setLookAt(cameraPosition[0], cameraPosition[1], cameraPosition[2], cameraFocus[0], cameraFocus[1], cameraFocus[2], true)
  }
  const cameraMoverDown=()=>{

    if(isMobile){
      cameraPosition[1] = cameraPosition[1]+4
      cameraFocus[1] = cameraFocus[1]+4
      cameraPosition[2] = cameraPosition[2]-6
    }else{
      cameraPosition[1] = cameraPosition[1]+6
      cameraFocus[1] = cameraFocus[1]+6
      cameraPosition[2] = cameraPosition[2]-5
    }

    controls?.setLookAt(cameraPosition[0], cameraPosition[1], cameraPosition[2], cameraFocus[0], cameraFocus[1], cameraFocus[2], true)
  }
  const vec = new Vector3()
  useFrame(() => {
    if((params?.id == undefined || params?.id == "#" ) & !isMobile  ){

      camera.position.lerp(vec.set(mouse.x, mouse.y, camera.position.z), 0.7) 
      camera.lookAt(cameraFocus[0], cameraFocus[1], cameraFocus[2])
    }
 })
  useEffect(() => {
    // console.log(isMobile)
    const active = scene.getObjectByName(params?.id)
    // const { camera, mouse } = useThree()
  

    const removeMovement = () => {
      controls.dollySpeed = 0;
      controls.azimuthRotateSpeed = 0;
      controls.polarRotateSpeed =0;
    }
    const enableMovement = () => {
      controls.dollySpeed = 1
      controls.azimuthRotateSpeed = 1
      controls.polarRotateSpeed =1;
    }

    if(moveCamera){
      cameraMover();
      setTimeout(()=>setShowUI(true),500);
    }
    else if (moveCameraDown){
      // console.log("heyy")
      cameraMoverDown();
     
      
      

    }

    else if (changingView) {

      if (view == 0) {
        cameraPosition[0] =isMobile?37.2:40
        cameraPosition[1] = 0
        cameraPosition[2] = isMobile?-125:-130
        cameraFocus[0] = isMobile?38:40
        cameraFocus[1] = 0
        cameraFocus[2] = -150
      } else if (view == 1) {
        cameraPosition[0] = isMobile?50:52
        cameraPosition[1] = 0
        cameraPosition[2] = isMobile?-131.5:-136.5
        cameraFocus[0] = isMobile?50:52
        cameraFocus[1] = 0
        cameraFocus[2] = -150
      } else if (view == 2) {
        cameraPosition[0] = isMobile?62.5:64
        cameraPosition[1] = 0
        cameraPosition[2] = isMobile?-138:-143.5
        cameraFocus[0] = isMobile?62.5:64
        cameraFocus[1] = 0
        cameraFocus[2] = -150
      }
      else if (view == 3) {
        cameraPosition[0] = isMobile?74.5:76.5
        cameraPosition[1] = 0
        cameraPosition[2] = isMobile?-145:-150.5
        cameraFocus[0] = isMobile?74.5:76.5
        cameraFocus[1] = 0
        cameraFocus[2] = -200
      }
      else if (view == 4) {
        cameraPosition[0] = isMobile?87:89
        cameraPosition[1] = 0
        cameraPosition[2] = isMobile?-152:-157
        cameraFocus[0] = isMobile?87:89
        cameraFocus[1] = 0
        cameraFocus[2] = -200
      }
      else if (view == 5) {
        cameraPosition[0] = isMobile?99:101
        cameraPosition[1] = 0
        cameraPosition[2] = isMobile?-158:-164
        cameraFocus[0] = isMobile?99:101
        cameraFocus[1] = 0
        cameraFocus[2] = -200
      }
      else if (view == 6) {
        cameraPosition[0] = isMobile?111.5:113.5
        cameraPosition[1] = 0
        cameraPosition[2] = isMobile?-165:-170
        cameraFocus[0] = isMobile?111.5:113.5
        cameraFocus[1] = 0
        cameraFocus[2] = -250
      }
      else if (view == 7) {
        cameraPosition[0] = isMobile?123.8:125.5
        cameraPosition[1] = 0
        cameraPosition[2] = isMobile?-172:-177
        cameraFocus[0] = isMobile?124:125.5
        cameraFocus[1] = 0
        cameraFocus[2] = -250
      }      else if (view == 8) {
        cameraPosition[0] = isMobile?136.1:137.5
        cameraPosition[1] = 0
        cameraPosition[2] = isMobile?-178:-184
        cameraFocus[0] = isMobile?136.5:137.5
        cameraFocus[1] = 0
        cameraFocus[2] = -250
      }


      setchangingView(false)
    }
    else if(mainChangingView){
      if (mainView == 0) {
        cameraPosition[0] = 0
        cameraPosition[1] = 0
        cameraPosition[2] = isMobile?3:2.5
        cameraFocus[0] = 0
        cameraFocus[1] = 0
        cameraFocus[2] = 0
      } else if (mainView == -1) {
        cameraPosition[0] = 0
        cameraPosition[1] = 0
        cameraPosition[2] = 2.5
        cameraFocus[0] = -2
        cameraFocus[1] = 0
        cameraFocus[2] = 0
      } else if (mainView == 1) {
        cameraPosition[0] = 0
        cameraPosition[1] = 0
        cameraPosition[2] = 2.5
        cameraFocus[0] = 2
        cameraFocus[1] = 0
        cameraFocus[2] = 0
      }
      setmainChangingView(false)

    }
     else {
      if (active) {
        switch (params?.id) {
          case '01':
            cameraPosition[0] =isMobile?37.2:40
            cameraPosition[1] = 0
            cameraPosition[2] = isMobile?-125:-130
            cameraFocus[0] = isMobile?38:40
            cameraFocus[1] = 0
            cameraFocus[2] = -150
            removeMovement();
            break
          case '02':
            cameraPosition[0] = isMobile?-3:0.8
            cameraPosition[1] = isMobile?-1.2:-0.5
            cameraPosition[2] =isMobile?7:3.5
            cameraFocus[0] = isMobile?7:-19
            cameraFocus[1] = isMobile?122:-0.5
            cameraFocus[2] =  isMobile?-10:-10
            removeMovement()

            break
          case '03':
            cameraPosition[0] =isMobile?13: 16
            cameraPosition[1] = -0.2
            cameraPosition[2] = isMobile?-7:-14
            cameraFocus[0] = 50
            cameraFocus[1] = -0.2
            cameraFocus[2] = -100
            removeMovement()


            break
          default:
            break
        }
        controls?.setLookAt(cameraPosition[0], cameraPosition[1], cameraPosition[2], cameraFocus[0], cameraFocus[1], cameraFocus[2], true)
      } else {
        cameraPosition[0] = 0
        cameraPosition[1] = 0
        cameraPosition[2] = isMobile?3:2.5
        cameraFocus[0] = 0
        cameraFocus[1] = 0
        cameraFocus[2] = 0
        setView(0);
        setmainView(0);
        setShowUI(false);
        try{

          enableMovement();
        }catch(e){
          // console.log("hello i am here")
        }

      }
    }
    // console.log(cameraPosition, cameraFocus)
    controls?.setLookAt(cameraPosition[0], cameraPosition[1], cameraPosition[2], cameraFocus[0], cameraFocus[1], cameraFocus[2], true)


  }, [params?.id, scene, controls, cameraPosition, cameraFocus,moveCamera,moveCameraDown])


  return (
    <>
      <CameraControls

     
        
        makeDefault
        minPolarAngle={0}
        maxPolarAngle={Math.PI / 2}
      />
    </>
  )
}

export default Rig
