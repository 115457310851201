import React from 'react'
import { useRoute } from 'wouter'

import style from './index.module.css'
const LapUi = () => {
  const [, params] = useRoute('/item/:id')
  if (params?.id === '03') {
    return (
      <div className={style.divv}>
        <div className={style.icons}>
          <a className={style.links} href="https://in.linkedin.com/in/rahul-vuppala-702675115" target='_blank' >
            <img width="40px" src="/images/linkedIN.png" alt="" />  
          </a>

          <a className={style.links} href="https://twitter.com/RahulVuppa69329" target='_blank'>
            <img width="40px" src="/images/twitter.png" alt="" />
          </a>

          <a className={style.links} href="https://www.upwork.com/freelancers/~01a7481f37bd470345" target='_blank'>
            <img width="40px" src="/images/upwork.png" alt="" />
          </a>

          <a className={style.links} href="https://www.freelancer.in/u/VuppalaRahul " target='_blank'>
            <img width="40px" src="/images/freelancer.png" alt="" />
          </a>

          <a className={style.links} href="https://www.github.com/Rahulvvsv" target='_blank'>
            <img width="40px" src="/images/github.png" alt="" />
          </a>
        </div>
      </div>
    )
  } else {
    return <div></div>
  }
}

export default LapUi
