import React from 'react'
import { useRoute } from 'wouter'
import { useCamera } from '../camContext/cameraContext'

import style from './index.module.css'
const LandingMobileUi = () => {
  const [, params] = useRoute('/item/:id')
  const { mainView, setmainView, updateCamPosition2,loaded } = useCamera()

  const mainlocationChanger = (movement) => {
    let newPosition = [0, 0, 0]
    let newFocus = [0, 0, 0]
    if (movement == 'right') {
      
        setmainView((prevmainView) => prevmainView + 1)
      
    } else if (movement == 'left') {
        setmainView((prevmainView) => prevmainView - 1)
      
    }
    // console.log(mainView)
    updateCamPosition2(newPosition,newFocus)
    }

  if ((params?.id === undefined) && loaded) {
    return (
      <div className={style.main}>
        <div className={style.arrows}>
          {mainView >= 0 ? <div >
            <img className={style.left} src="/images/left1.png" onClick={()=> mainlocationChanger('left')} alt="" />
          </div>
             : ''}
          {mainView <= 0 ?<div>
            <img className={style.right} src="/images/right1.png" onClick={()=> mainlocationChanger('right')} alt="" /> 
            </div>
            : ''}
        </div>
      </div>
    )
  } else {
    return <div></div>
  }
}

export default LandingMobileUi
