// CameraContext.js
import React, { createContext, useContext, useState } from 'react'

const CameraContext = createContext()

export const useCamera = () => {
  return useContext(CameraContext)
}

export const CameraProvider = ({ children }) => {
  const [cameraPosition, setCameraPosition] = useState([0, 0, 2.5])
  const [cameraFocus, setCameraFocus] = useState([0, 0, 0])
  const [changingView, setchangingView] = useState(false)
  const [view, setView] = useState(0)
  const [showUI, setShowUI] = useState(false)
  const [mainView, setmainView] = useState(0)
  const [mainChangingView, setmainChangingView] = useState(false)
  const [moveCamera, setmoveCamera] = useState(false)
  const [loaded,setLoaded] = useState(false)
  const [moveCameraDown, setmoveCameraDown] = useState(false);
  const [showLoading, setShowLoading] = useState(true)
  const [showAnimation, setShowAnimation] = useState(false)


  const project_array = ['SUHAaaS', 'MarketingWebsite', 'game', 'MamaLang', 'Dog', 'chromeExtension', 'liveStream', 'Algorithm',"manyMore"]
  const videoArray = {
    game: {
      demolink:
        'https://firebasestorage.googleapis.com/v0/b/portfolio3d-430ee.appspot.com/o/efa2318e-c2ed-453c-af78-3fef9795b2ba.mp4?alt=media&token=929ee0fe-e685-4837-8e1e-b9a64862a7a3&_gl=1*1tg0ad*_ga*MjA1NzA0MTk2Ny4xNjk3MDkwMDcx*_ga_CW55HF8NVT*MTY5ODQzMjQwOC42LjEuMTY5ODQzNDA1OC42MC4wLjA.',
      seeDemo: true,
      isLink: false,
      isVideo: true,
      github: true,
      githublink: 'https://github.com/CodeGenies120/Unreal'
    },
    chromeExtension: {
      demolink:
        'https://firebasestorage.googleapis.com/v0/b/portfolio3d-430ee.appspot.com/o/LeadZilla%20Loom%20Video%20LinkedIn%20Extension(2).mp4?alt=media&token=ebc04652-8864-40d3-9c20-37ba6c688473&_gl=1*12nni3n*_ga*MjA1NzA0MTk2Ny4xNjk3MDkwMDcx*_ga_CW55HF8NVT*MTY5ODQzMjQwOC42LjEuMTY5ODQzNDE4Mi4xNy4wLjA.',
      seeDemo: true,
      isLink: false,
      isVideo: true,
      github: false,
      githublink: ''
    },
    MarketingWebsite: {
      demolink: 'https://aidtaas.com/',
      seeDemo: true,
      isLink: true,
      isVideo: false,
      github: false,
      githublink: ''
    },
    MamaLang: {
      demolink: '',
      seeDemo: false,
      isLink: false,
      isVideo: false,
      github: true,
      githublink: 'https://github.com/Rahulvvsv/MAMA-LANG'
    },
    liveStream: {
      demolink: 'https://firebasestorage.googleapis.com/v0/b/portfolio3d-430ee.appspot.com/o/liveStream.mp4?alt=media&token=4b635dd9-5742-4586-9118-954d9000658d&_gl=1*s02qib*_ga*MjA1NzA0MTk2Ny4xNjk3MDkwMDcx*_ga_CW55HF8NVT*MTY5ODY5MDE0OC4xMS4xLjE2OTg2OTAxODIuMjYuMC4w',
      seeDemo: true,
      isLink: false,
      isVideo: true,
      github: false,
      githublink: 'https://github.com/Rahulvvsv/MAMA-LANG'
    },
    Algorithm: {
      demolink:
        'https://firebasestorage.googleapis.com/v0/b/portfolio3d-430ee.appspot.com/o/Bubble_sort_visual.mp4?alt=media&token=63b89321-45ad-421d-9ec7-393edcd006ee&_gl=1*13nxc4e*_ga*MjA1NzA0MTk2Ny4xNjk3MDkwMDcx*_ga_CW55HF8NVT*MTY5ODY3OTc3MS45LjEuMTY5ODY3OTgwMC4zMS4wLjA.',
      seeDemo: true,
      isLink: false,
      isVideo: true,
      github: true,
      githublink: 'https://github.com/Rahulvvsv/Bubble_Sort_Visualizer'
    },
    SUHAaaS: {
      demolink:
        'https://firebasestorage.googleapis.com/v0/b/portfolio3d-430ee.appspot.com/o/SUHAaaS.mp4?alt=media&token=df17f066-39fd-402c-86b4-d3f7edff3eb8&_gl=1*1k3ronl*_ga*MjA1NzA0MTk2Ny4xNjk3MDkwMDcx*_ga_CW55HF8NVT*MTY5ODY3OTc3MS45LjEuMTY5ODY4MDkxNS4zMi4wLjA.',
      seeDemo: true,
      isLink: false,
      isVideo: true,
      github: false,
      githublink: 'https://github.com/Rahulvvsv/Bubble_Sort_Visualizer'
    },
    Dog: {
      demolink:
        '',
      seeDemo: false,
      isLink: false,
      isVideo: false,
      github: true,
      githublink: 'https://github.com/Rahulvvsv/Projects-2021/tree/master/Deep-learning'
    },
    manyMore: {
      demolink:
        '',
      seeDemo: false,
      isLink: false,
      isVideo: false,
      github: true,
      githublink: 'https://github.com/Rahulvvsv'
    }
  }

  const updateCameraPosition = (position, focus) => {
    setCameraPosition(position)
    setCameraFocus(focus)
    setchangingView(true)
    // console.log('setting done')
  }

  const updateCamPosition2 = (position, focus) => {
    setCameraPosition(position)
    setCameraFocus(focus)
    setmainChangingView(true)
  }

  return (
    <CameraContext.Provider
      value={{
        cameraPosition,
        cameraFocus,
        updateCameraPosition,
        changingView,
        setchangingView,
        view,
        setView,
        mainView,
        setmainView,
        mainChangingView,
        setmainChangingView,
        updateCamPosition2,
        project_array,
        videoArray,
        setShowUI,
        showUI,
        moveCamera,
        setmoveCamera,
        loaded,
        setLoaded,
        moveCameraDown,
        setmoveCameraDown,
        showLoading,
        setShowLoading,
        showAnimation,
        setShowAnimation
      }}>
      {children}
    </CameraContext.Provider>
  )
}
