import React from 'react'
import { Canvas, useLoader } from '@react-three/fiber'
import { Environment } from '@react-three/drei'
import { TextureLoader } from 'three'

function PlaneWithTexture({ textureUrl }) {
  // Load the texture using TextureLoader
  const texture = useLoader(TextureLoader, textureUrl)

  return (
    <mesh>
      <planeGeometry  args={[9, 5]} />
      <meshBasicMaterial  map={texture} transparent />
    </mesh>
  )
}

function ProjectSlide({ position,url,scale,rotation=[0, -0.5, 0] }) {
  return (
    <group position={position} rotation={rotation}  scale={scale}>
      <pointLight ></pointLight>
      <ambientLight intensity={1} />

      <PlaneWithTexture textureUrl={url} />
    </group>
  )
}

export default ProjectSlide
