// Hud.js
import React from 'react'
import { useRoute } from 'wouter'
import style from './index.module.css'
import { useCamera } from '../camContext/cameraContext'
import { useState } from 'react'
const Hud = () => {
  const [loading, setLoading] = useState(false)
  const [, params] = useRoute('/item/:id')
  const { updateCameraPosition, view, setView, project_array, videoArray, showUI, setShowUI, moveCamera, setmoveCamera,setmoveCameraDown } = useCamera()
  const locationChanger = (movement) => {
    setmoveCamera(false)
    setShowUI(false)
    setmoveCameraDown(false)
    let newPosition = [0, 0, 0]
    let newFocus = [0, 0, 0]
    if (movement == 'right') {
      if (view < 8) {
        setView((prevView) => prevView + 1)
      }
    } else if (movement == 'left') {
      if (view >= 0) {
        setView((prevView) => prevView - 1)
      }
    }
    updateCameraPosition(newPosition, newFocus)
  }

  if (params?.id === '01') {
    return (
      <div>
        <div className={style.hud}>
          <div className={style.arrows}>
            {view > 0 ? <img className={style.left} onClick={() => locationChanger('left')} src="/images/left1.png" alt="" /> : ''}
            {view < 8 ? <img className={style.right} onClick={() => locationChanger('right')} src="/images/right1.png" alt="" /> : ''}
          </div>
        </div>
        <div className={style.but}>
          {videoArray[project_array[view]].seeDemo ? (
            <button
              className={style.demo}
              onClick={() => {
                // console.log('clicked')
                if (videoArray[project_array[view]].isLink) {
                  window.open(videoArray[project_array[view]].demolink, '_blank')
                } else {
                  setmoveCamera(true)
                }
              }}>
              <span>See Demo</span>
            </button>
          ) : (
            ''
          )}

          {videoArray[project_array[view]].github ? (
            <button
              className={style.Github}
              onClick={() => {
                window.open(videoArray[project_array[view]].githublink, '_blank')
              }}>
              <span>Github Link</span>
            </button>
          ) : (
            ''
          )}
        </div>

        {showUI ? (
          <div className={style.grey}>
            <h1 className={style.close} onClick={()=>{
              setmoveCamera(false)
              setShowUI(false);
              setmoveCameraDown(true);
              setLoading(false);
            }}><img src='/images/close.png' className={style.image1}></img></h1>
            <img src={`/images/projects2/${view + 1}.png`} className={style.image} alt="" />
          </div>
        ) : (
          ''
        )}
        {loading ? <img className={style.vid1} src="/loading.svg"></img> : ''}
        <div className={style.vid}>
          {showUI ? (
            <video
              src={videoArray[project_array[view]].demolink}
              muted
              className={style.player}
              autoPlay
              onLoadStart={() => {
                setLoading(true)
                // console.log('loading start')
              }}
              onCanPlay={() => {
                setLoading(false)
                // console.log('loading done')
              }}></video>
          ) : (
            ''
          )}
        </div>
      </div>
    )
  } else {
    return <div></div>
  }
}

export default Hud
