import { createRoot } from 'react-dom/client'
import './styles.css'
import { App } from './App'
import Hud from './ProjectsUI'
import { CameraProvider } from './camContext/cameraContext'
import AvatarUI from './AvatarUI'
import LapUi from "./lapUI/index"
import { useEffect } from 'react'
import LoadingScreen from './loadingScreen'

import LandingMobileUi from './landingMobileUi'
function Root() {

  console.log("Hey there what are you looking for?");
  console.log("zoom out and you'll find out something lol😂");

  return (
    <>
    
    <CameraProvider>

      <App />
      <LoadingScreen></LoadingScreen>
      <Hud></Hud>
      <AvatarUI></AvatarUI>
      <LapUi></LapUi>
      <LandingMobileUi></LandingMobileUi>
    </CameraProvider>

    </>
  )
}

createRoot(document.getElementById('root')).render(<Root />)
