import {
	Html,
	OrbitControls,
	Preload,
	useAnimations,
	useGLTF,
} from "@react-three/drei";
import { Suspense, useEffect, useRef, useState } from "react";

const Avatar = () => {
	const avatar = useGLTF("/rahul2.glb");

	const { actions, names } = useAnimations(avatar.animations, avatar.scene);
	useEffect(() => {
		actions.idle.play()
		


	});

	return (
		<group>
			<primitive
				object={avatar.scene}
				scale={1}
                position-z={-0}
                position-y={-1.3}
		
			/>

			
		</group>
	);
};



 const AvatarScene = () => {
	return (
		<>

			<ambientLight intensity={0.2} />
			<Avatar />
			
			
			<Preload all />
		</>
	);
};
export default AvatarScene;