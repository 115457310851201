import * as THREE from "three";
import { Canvas, extend } from "@react-three/fiber";
import { Text } from "@react-three/drei";
import { geometry } from "maath";
import { Environment } from "@react-three/drei";
import Laptop from "./laptop/App";
import Rig from "./Rig/rig";
import Frame from "./Frame/frame";
import ProjectSlide from "./projects";
import AvatarScene from "./Avatar/index";
import { Suspense } from "react";
import RobotScene from "./robot";
import { useRoute, useLocation } from "wouter";
import ImageSphere from "./videoSphere/index.jsx";
// import GradientText from "./MainHeading/index.jsx";
extend(geometry);
import { useCamera } from "./camContext/cameraContext";

export const App = () => {
  let isMobile = window.innerWidth < 768 ? true : false;
  const { setShowUI, setmoveCamera, setmoveCameraDown } = useCamera();

  const [, params] = useRoute("/item/:id");
  const [, setLocation] = useLocation();

  return (
    <div style={{ position: "fixed", width: "100vw", height: "100vh" }}>
      <Canvas
        camera={{ fov: 80, position: [0, 0, 25] }}
        eventSource={document.getElementById("root")}
        eventPrefix="client"
      >
        <Suspense fallback={null}>
          <>
            <ambientLight intensity={0.002}></ambientLight>
            <Text fontSize={13} position={[0, 65, 0]} color="black">
              Infinite void
            </Text>
            <ProjectSlide
              scale={10}
              url="images/keanu.jpg"
              position={[-120, 20, 0]}
              rotation={[0, 0, 0]}
            ></ProjectSlide>
            <ProjectSlide
              scale={10}
              url="images/Gojo.jpg"
              position={[120, 20, 0]}
              rotation={[0, 0, 0]}
            ></ProjectSlide>
            <ImageSphere imageUrl={"images/gradientTexture7.jpg"}></ImageSphere>
            <Text
              fontSize={0.3}
              position={[0, isMobile ? 1.3 : 1.4, 0]}
              color="black"
            >
              Hello There!
            </Text>
            <Text
              fontSize={0.3}
              position={[0, isMobile ? 0.95 : 1.1, 0]}
              color="black"
            >
              I'm Rahul
            </Text>


            <color attach="background" args={["#f0f0f0"]} />
            <Text
              fontSize={0.1}
              position={[-2.1, -0.7, 0]}
              color="black"
              rotation={[0, 0.5, 0]}
            >
              Projects
            </Text>
            <RobotScene></RobotScene>
            <Frame
              id="01"
              bg="black"
              position={[-2, 0, 0]}
              rotation={[0, 0.5, 0]}
            >
              <Environment
                files="4.hdr"
                blur={0.3}
                rotation={[0, 0.5, 1]}
                background
                position={[2, 2, 2]}
              />
              <ProjectSlide
                position={[100, 0, -100]}
                url={"images/projects2/1.png"}
              ></ProjectSlide>
              <ProjectSlide
                position={[114, 0, -100]}
                url={"images/projects2/2.png"}
              ></ProjectSlide>
              <ProjectSlide
                position={[128, 0, -100]}
                url={"images/projects2/3.png"}
              ></ProjectSlide>
              <ProjectSlide
                position={[142, 0, -100]}
                url={"images/projects2/4.png"}
              ></ProjectSlide>
              <ProjectSlide
                position={[156, 0, -100]}
                url={"images/projects2/5.png"}
              ></ProjectSlide>
              <ProjectSlide
                position={[170, 0, -100]}
                url={"images/projects2/6.png"}
              ></ProjectSlide>
              <ProjectSlide
                position={[184, 0, -100]}
                url={"images/projects2/7.png"}
              ></ProjectSlide>
              <ProjectSlide
                position={[198, 0, -100]}
                url={"images/projects2/8.png"}
              ></ProjectSlide>
              <ProjectSlide
                position={[212, 0, -100]}
                url={"images/projects2/9.png"}
              ></ProjectSlide>
            </Frame>

            <Text
              fontSize={0.1}
              position={[-0.05, -0.7, 0]}
              color="black"
              rotation={[0, -0, 0]}
            >
              About
            </Text>
            <Frame id="02" position={[0, 0, 0]} name="" bg={"white"}>
              <ambientLight></ambientLight>
              <AvatarScene></AvatarScene>
              <Environment
                files="9.hdr"
                blur={0.5}
                background
                position={[2, 2, 2]}
              />
            </Frame>
            <Text
              fontSize={0.1}
              position={[1.9, -0.7, 0]}
              color="black"
              rotation={[0, -0.5, 0]}
            >
              Contact
            </Text>
            <Frame id="03" name="" position={[2, 0, 0]} rotation={[0, -0.5, 0]}>
              <Laptop></Laptop>
              <ambientLight></ambientLight>
              <Environment
                files="5.hdr"
                blur={0.4}
                background
                position={[2, 2, 2]}
              />
            </Frame>

            <Rig />
          </>
        </Suspense>
      </Canvas>
      <div>
        {params?.id == undefined || params?.id == "#" ? (
          <div
            style={{
              position: "absolute",
              bottom: 40,
              right: 40,
              fontSize: "13px",
            }}
          >
            Developed with 🖤
          </div>
        ) : (
          ""
        )}
        <a
          style={{
            position: "absolute",
            top: 40,
            left: 20,
            fontSize: "15px",
            zIndex: 100,
          }}
          href="#"
          onClick={() => {
            setmoveCamera(false);
            setShowUI(false);
            setmoveCameraDown(false);
            setLocation("/");
          }}
        >
          {params ? "< back" : " "}
        </a>
      </div>
    </div>
  );
};
