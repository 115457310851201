import {
	Html,
	OrbitControls,
	Preload,
	useAnimations,
	useGLTF,
} from "@react-three/drei";
import { useEffect } from "react";

const Robot = () => {
	const robot = useGLTF("/robot.glb");
	const { actions, names } = useAnimations(robot.animations, robot.scene);
	useEffect(() => {
		// console.log(actions)
		actions.Animation.play();


	});

	return (
		<group>
			<primitive
				object={robot.scene}
				scale={1.2}
                position={[-2, -0.3, 0.1]} 
				rotation={[0,0.3,0]}
			/>
			{/* <pointLight color={"red"}  intensity={1000} position={[-1, -1, -1]}></pointLight> */}
			
		</group>
	);
};



 const RobotScene = () => {
	return (
		<>

			<Robot />
			
			<Preload all />
		</>
	);
};
export default RobotScene;