import React from 'react'
import { useRoute } from 'wouter'
import style from './index.module.css'
import useLocation from 'wouter/use-location'
const AvatarUI = () => {
  const [, params] = useRoute('/item/:id')
  const [, setLocation] = useLocation()

  if (params?.id === '02') {
    return (
      <>
              <a style={{ position: 'absolute', top: 40, left: 20, fontSize: '15px',zIndex:100 }} href="#" onClick={() => setLocation('/')}>
          {params ? '< back' : ' '}
        </a>
      <div style={{ position: 'absolute' }}>
        <div className={style.main} >
          <div className={style.bio}>
            <div>
              <p className={style.mainText} >Hello</p>
              <p className={style.mainText2}>I'm Rahul Vuppala</p>
              <p className={style.mainText3}>Software Developer | Freelancer | Tech Enthusiast</p>
              <p className={style.mainText4}>
                I'm an experienced software developer who really knows their stuff when it comes to computer science. I'm all about making cool projects that make life better for
                people. Plus, I'm a big fan of both movies and tech, and I like to bring a mix of techie know-how and creative thinking to everything I do
              </p>
            </div>

          
            <div className={style.bio2parent}>
              <div className={style.bio2 + " "+ style.skills}>
                <p className={style.mainText5}>EXPERIENCES</p>
                <div className={style.bio3}>
                  <div className={style.innerChild}>
                    <p className={style.name}>GAIAN SOLUTIONS</p>
                    <p className={style.name2}>SDE-1</p>
                  </div>
                  <div className={style.innerChild}>
                    <p className={style.name}>ZEMOSO TECHNOLOGIES</p>
                    <p className={style.name2}>INTERN</p>
                  </div>
                  <div className={style.innerChild}>
                    <p className={style.name}>FREELANCER.IN</p>
                    <p className={style.name2}>FREELANCER</p>
                  </div>
                  <div className={style.innerChild}>
                    <p className={style.name}>IDEA INCUBATOR MGIT | R&D</p>
                    <p className={style.name2}>VICE PRESIDENT</p>
                  </div>
                  <div className={style.innerChild}>
                    <p className={style.name}>CEREBRO TEAM | THE FINANCIAL LITERACY ACADEMY</p>
                    <p className={style.name2}>INTERN</p>
                  </div>
                </div>
              </div>

              <div className={style.bio2 + " "+ style.skills}>
                <p className={style.mainText5}>SKILLS</p>
                <div className={style.bio3}>
                  <div className={style.innerChild}>
                    <p className={style.name3}>PYTHON</p>
                  </div>
                  <div className={style.innerChild}>
                    <p className={style.name3}>JAVA SCRIPT, TYPE SCRIPT</p>
                  </div>
                  <div className={style.innerChild}>
                    <p className={style.name3}>C, JAVA</p>
                  </div>
                  <div className={style.innerChild}>
                    <p className={style.name3}>C++, C++ SFML</p>
                  </div>
                  <div className={style.innerChild}>
                    <p className={style.name3}>FRONT END (REACT, ANGULAR, STORYBOOK)</p>
                  </div>
                  <div className={style.innerChild}>
                    <p className={style.name3}>BACK END (DJANGO, EXPRESS)</p>
                  </div>
                  <div className={style.innerChild}>
                    <p className={style.name3}>SQL</p>
                  </div>
                  <div className={style.innerChild}>
                    <p className={style.name3}>SHELL SCRIPTS, COMPUTER NETWORKS</p>
                  </div>
                  <div className={style.innerChild}>
                    <p className={style.name3}>MACHINE LEARNNIG (SCIKIT LEARN, PANDAS, NUMPY, TENSORFLOW)</p>
                  </div>
                </div>
              </div>

            </div>
            <div className={style.bio2parent}>
            <div className={style.bio2}>
                <p className={style.mainText5}>EDUCATION</p>
                <div className={style.bio3}>
                  <div className={style.innerChild}>
                    <p className={style.name}>COMPUTER SCIENCE, MGIT</p>
                  </div>


                </div>
              </div>

              <div className={style.bio2 }>
              <p className={style.mainText5}>LANGUAGES KNOWN</p>
                <div className={style.bio3}>
                  <div className={style.innerChild}>
                    <p className={style.name}>ENGLISH, HINDI, TELUGU</p>
                  </div>


                </div>

              </div>

            </div>


            <div>
              <a href="https://firebasestorage.googleapis.com/v0/b/portfolio3d-430ee.appspot.com/o/VUPPALA%20RAHUL%20RESUME.pdf?alt=media&token=a5d1a64b-326c-4a2b-a516-9407a0c3341a" download={"Rahulresume"} target='blank'><button><span>Resume</span></button></a>
            </div>
          </div>
        </div>
      </div>
    </>
    )
  } else {
    return <div></div>
  }
}

export default AvatarUI
