import React, { Suspense, useState, useEffect } from 'react'
import { Canvas } from '@react-three/fiber'
import { Html, useProgress } from '@react-three/drei'
import style from './index.module.css'
import { useCamera } from '../camContext/cameraContext'

function LoadingScreen() {
  const { progress } = useProgress()
  const [showLoading, setShowLoading] = useState(true)
  const [showAnimation, setShowAnimation] = useState(false)
  const { setLoaded } = useCamera()
  let isMobile = window.innerWidth < 768 ? true : false;
  let progressMultiplier = isMobile ? 0.12 : 0.25;
  let radius = progress * progressMultiplier;
  if (progress === 100) {
    setTimeout(() => {
      setShowAnimation(true);
    }, 1000);

    setTimeout(() => {
      setShowLoading(false);
      setLoaded(true);
    }, 6000);
  }

  if (showLoading) {
    return (
      <>
        <div className={style.main}>
          <div
            className={style['loading-circle']}
            style={{
              width: `${radius * 100}px`,
              height: `${radius * 100}px`,
              overflow: 'hidden',
              stroke:0,
              border:0
            }}
          ></div>
          <div className={style['inverse-text']}>SIMPLICITY</div>
        </div>
        <p
          style={{
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)',
            bottom: '10px',
            mixBlendMode: 'difference',
            color: 'white',
          }}
        >
          {Math.round(progress)}%
        </p>

        {showAnimation && (
          <div className={style.main2}>
            <div className={style['text1']}>Click on a portal to enter it!</div>
          </div>
        )}
      </>
    );
  } else {
    return null;
  }
}

export default LoadingScreen;
