import * as THREE from 'three'
import { useEffect, useRef, useState } from 'react'
import { Canvas, extend, useFrame, useThree } from '@react-three/fiber'
import { useCursor, MeshPortalMaterial, CameraControls, Gltf, Text } from '@react-three/drei'
import { useRoute, useLocation } from 'wouter'
import { easing, geometry } from 'maath'


function Frame({ id, name, author, bg, width = 1.5, height = 1.2, children, ...props }) {
  
    const portal = useRef()
    const [, setLocation] = useLocation()
    const [, params] = useRoute('/item/:id')
    const [hovered, hover] = useState(false)
    useCursor(hovered)
    useFrame((state, dt) => easing.damp(portal.current, 'blend', params?.id === id ? 1 : 0, 0.2, dt))
    return (
      <group {...props}>
        <Text  fontSize={0.2} anchorY="top" anchorX="left" color={'black'} lineHeight={0.8} position={[-0.69, 0.5, 0.01]} material-toneMapped={false}>
          {name}
        </Text>
        <mesh name={id}  onClick={(e) => (e.stopPropagation(), setLocation('/item/' + e.object.name))} onPointerOver={(e) => hover(true)} onPointerOut={() => hover(false)}>
          <roundedPlaneGeometry args={[width, height, 0.07]} />
  
          <MeshPortalMaterial ref={portal} events={params?.id === id} side={THREE.DoubleSide} >
  
            {children}
          </MeshPortalMaterial>
        </mesh>
      </group>
    )
  }
export default Frame;  